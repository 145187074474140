import React, { useState, useRef } from "react"
import { StaticQuery, graphql } from "gatsby"

import DateBar from "../components/DateBar"
import LocationBar from "../components/LocationBar"
import UrgentMessage from "../components/UrgentMessage"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FancyText from "../components/FancyText"
import Tbc from "../components/Tbc"

import Loading from "../components/Loading"

const IndexPage = () => {
  const [frameLoaded, setFrameLoaded] = useState(false)
  const frame = useRef()
  return (
    <StaticQuery
      query={graphql`
        query INDEX_QUERY {
          markdownRemark(fileAbsolutePath: { regex: "/index.md$/" }) {
            html
            frontmatter {
              date {
                date
                dateSuffix
                month
                year
                time
                timeShort
                day
              }
              tagline
              subtitle
              location {
                name
                address
                area
              }
              showRsvp
            }
          }
          faq: markdownRemark(fileAbsolutePath: { regex: "/faq.md$/" }) {
            html
            frontmatter {
              title
              show
            }
          }
          urgent: markdownRemark(fileAbsolutePath: { regex: "/urgent.md$/" }) {
            frontmatter {
              use
              message
              hideSpecifics
              showMessage
            }
          }
        }
      `}
    >
      {({ markdownRemark, faq, urgent }) => (
        <Layout
          urgentMessage={() =>
            urgent.frontmatter.use && (
              <UrgentMessage
                urgentMessage={urgent.frontmatter.message}
                showUrgentMessage={urgent.frontmatter.showMessage}
              />
            )
          }
        >
          <SEO keywords={[`gatsby`, `application`, `react`]} />
          <h1 className="title">
            Georgiana
            <br />
            and Codie
          </h1>
          <section className="content content--date">
            <p className="p--narrow tagline">
              <em>{markdownRemark.frontmatter.tagline}</em>
            </p>
            <DateBar
              date={markdownRemark.frontmatter.date}
              hideSpecifics={urgent.frontmatter.hideSpecifics}
            />
          </section>
          <section className="content">
            <h2 className="fancy-header">
              <FancyText>{markdownRemark.frontmatter.subtitle}</FancyText>
            </h2>
            {urgent.frontmatter.hideSpecifics ? (
              <div style={{ marginTop: "1rem" }}>
                <Tbc title="Location" />
              </div>
            ) : (
              <>
                <LocationBar
                  location={markdownRemark.frontmatter.location}
                  hideSpecifics={urgent.frontmatter.hideSpecifics}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
                />
              </>
            )}
          </section>
          {markdownRemark.frontmatter.showRsvp && (
            <section className="content content--rsvp">
              <div className="rsvp__loading">
                <Loading isLoading={!frameLoaded}></Loading>
              </div>
              <iframe
                id="RSVPifyIFrame"
                className="rsvp__iframe"
                title="rsvpify"
                style={{
                  width: "100%",
                  border: "none",
                  display: frameLoaded ? "block" : "none",
                }}
                ref={frame}
                onLoad={() => {
                  if (frameLoaded) {
                    frame.current.scrollIntoView(true)
                  }
                  setFrameLoaded(true)
                }}
                onError={console.error}
                allowtransparency="true"
                src="https://thewestphalls.app.rsvpify.com/?embed=1&js=1"
                frameBorder="0"
                scrolling="no"
              ></iframe>
              <p className="rsvp__fallback">
                <a
                  href="https://thewestphalls.app.rsvpify.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  or click here if the RSVP form does not load
                </a>
              </p>
            </section>
          )}
          {faq.frontmatter.show && (
            <section className="content">
              <h2 className="fancy-header">
                <FancyText>{faq.frontmatter.title}</FancyText>
              </h2>
              <div dangerouslySetInnerHTML={{ __html: faq.html }}></div>
            </section>
          )}
        </Layout>
      )}
    </StaticQuery>
  )
}

export default IndexPage
