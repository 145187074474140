import React from "react"
import "./loading.css"

// TODO: Add time out and switch to a fallback link if x amount of time has passed
const Loading = ({ isLoading }) => {
  if (!isLoading) return null
  return (
    <div className="spinner">
      {[...new Array(8)].map((_, i) => (
        <div key={i} className="spinner__ball"></div>
      ))}
    </div>
  )
}

export default Loading
