import React from "react"
import PropTypes from "prop-types"
import Tbc from "../Tbc"

const DateBar = ({
  date: { date, dateSuffix, day, time, timeShort, month, year },
  hideSpecifics,
}) => {
  if (hideSpecifics) return <Tbc title="Date" />
  return (
    <div className="db__grid">
      <div className="db__line db__line--month"></div>
      <div className="db__line db__line--date"></div>
      <div className="db__line db__line--date db__line--date--right"></div>
      <div className="db__line db__line--year"></div>
      <h3 className="db__day">{day}</h3>
      <h4 className="db__month">{month}</h4>
      <h2 className="db__date">{date}</h2>
      <h4 className="db__year">{year}</h4>
      <h3 className="db__time">{time}</h3>
      <h3 className="db__time db__time--short">{timeShort}</h3>
    </div>
  )
}

DateBar.propTypes = {
  date: PropTypes.shape({
    date: PropTypes.string.isRequired,
    dateSuffix: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    timeShort: PropTypes.string.isRequired,
  }).isRequired,
  hideSpecifics: PropTypes.bool.isRequired,
}

export default DateBar
