import React from "react"
import PropTypes from "prop-types"

const FancyText = ({ children, text = "&" }) => [
  ...children
    .split(text)
    .map((str, i) =>
      i ? [<span className="fancy-text" children={text} key={i} />, str] : str
    ),
]

FancyText.propTypes = {
  children: PropTypes.string.isRequired,
}

export default FancyText
