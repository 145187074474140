import React from "react"

const UrgentMessage = ({ showUrgentMessage, urgentMessage }) => {
  if (!showUrgentMessage) return

  return (
    <div className="urgent__container">
      <div className="urgent__message">{urgentMessage}</div>
    </div>
  )
}

export default UrgentMessage
