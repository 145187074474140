import React from "react"
import PropTypes from "prop-types"

const LocationBar = ({ location: { name, address, area } }) => {
  return (
    <div className="location__grid">
      <div className="location__line"></div>
      <h4 className="location__address">{address}</h4>
      <h3 className="location__name">{name}</h3>
      <h4 className="location__area">{area}</h4>
      <div className="location__line"></div>
    </div>
  )
}

LocationBar.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired,
  }).isRequired,
}

export default LocationBar
